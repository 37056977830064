import Link from "next/link";
import { KeyedMutator } from "swr";
import { useMemo, useState } from "react";
import { deleteBudget, useCreatorTags } from "@/services/creator";
import NewEditBudget from "@/components/Modals/NewEditBudget";
import {
  Avatar,
  Flex,
  Heading,
  IconButton,
  Separator,
  Table,
  Text,
} from "@radix-ui/themes";
import { capitalize } from "@/lib/util";
import {
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandX,
  IconBrandYoutube,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import { useCampaignBudgets } from "@/services/campaign";
import useMobile from "@/lib/useMobile";
import Loader from "@/components/CustomUiComponents/Loader";

var groupBy = function (xs: any[], key: Function) {
  return xs.reduce(function (rv, x) {
    (rv[key(x)] = rv[key(x)] || []).push(x);
    return rv;
  }, {});
};

export const Budget = ({ budget, posts }: any) => {
  const getIcon = (budget) => {
    if (budget.creatorPrice.platform === "tiktok") {
      return <IconBrandTiktok className="icon" />;
    }
    if (budget.creatorPrice.platform === "instagram") {
      return <IconBrandInstagram className="icon" />;
    }
    if (budget.creatorPrice.platform === "twitter") {
      return <IconBrandX className="icon" />;
    }
    if (budget.creatorPrice.platform === "youtube") {
      return <IconBrandYoutube className="icon" />;
    }
  };

  const postCount = (posts: Post[], user: string, platform: string) => {
    return (
      posts?.filter(
        (p) =>
          p.username?.toLowerCase() === user.toLowerCase() &&
          p.type === platform
      ).length || 0
    );
  };
  const count = postCount(
    posts,
    budget?.creatorPrice.username,
    budget?.creatorPrice.platform
  );

  const budgetBg = () => {
    if (!budget) {
      return;
    }
    if (count <= budget.budget / 3) {
      return "#E56262";
    } else if (count < budget.budget) {
      return "#E5B062";
    } else if (count >= budget.budget) {
      return "#449E42";
    }
  };
  const [bg, setBg] = useState(budgetBg);

  const link = (platform: CreatorPrice) => {
    if (platform.platform === "instagram") {
      return `https://instagram.com/${platform.username}`;
    } else if (platform.platform === "tiktok") {
      return `https://tiktok.com/@${platform.username}`;
    } else if (platform.platform === "youTube") {
      return `https://youtube.com/${platform.username}`;
    } else if (platform.platform === "twitter") {
      return `https://twitter.com/${platform.username}`;
    }
    return "";
  };

  if (!budget) return <div></div>;

  const creatorPrice = budget?.customCreatorPrice || budget.creatorPrice?.price;
  return (
    <Flex gap="3" direction="row" align="center" width="100%" overflowX="clip">
      <Flex direction="row" width="17%" minWidth="130px">
        <Flex width="30%">
          <Text size="2">
            {count}/{budget.budget}
          </Text>
        </Flex>
        <Flex width="70%">
          <Text size="2">
            (${count * creatorPrice}/${budget.budget * creatorPrice})
          </Text>
        </Flex>
      </Flex>
      <Flex
        direction="row"
        justify="start"
        align="center"
        className="progress"
        style={{
          height: "20px",
          width: "58%",
          borderRadius: "100px",
        }}
      >
        {count === 0 && (
          <Flex
            width="100%"
            direction="row"
            justify="end"
            align="center"
            px="2"
          />
        )}
        <Flex
          style={{
            width: `${Math.min(
              Math.round((count * 100) / budget.budget),
              100
            )}%`,
            height: "20px",
            backgroundColor: bg,
            borderRadius: "100px",
          }}
        >
          {count !== 0 && (
            <Flex
              width="100%"
              direction="row"
              justify="end"
              align="center"
              px="2"
            ></Flex>
          )}
        </Flex>
      </Flex>
      <Flex direction="row" align="center" gap="2" width="25%">
        <Link href={link(budget.creatorPrice)} target="_blank">
          <Flex direction="row" gap="2" align="center">
            {getIcon(budget)}
            <Text size="1">@{budget.creatorPrice.username}</Text>
          </Flex>
        </Link>
      </Flex>
    </Flex>
  );
};

const find = (budgets: any[], platform: string) => {
  return budgets.filter((b) => b.creatorPrice.platform === platform);
};

interface CreatorsProps {
  campaign: Campaign;
  mutate: KeyedMutator<Campaign>;
  platforms: string[];
  selectedTags?: any;
}

const CreatorsTab = ({
  platforms,
  campaign,
  mutate,
  selectedTags,
}: CreatorsProps) => {
  const { isMobile } = useMobile();
  const { data: campaignBudgets } = useCampaignBudgets(campaign?.id as any);
  const { data: tags } = useCreatorTags();

  const lowerCasePlatforms = platforms?.map((p) => p.toLowerCase());

  const budgets =
    selectedTags?.length > 0
      ? campaign.budgets.filter(
          (b) =>
            selectedTags.includes(b.creatorPrice.creator.creatorType?.tag) ||
            (selectedTags.includes("Creator") &&
              b.creatorPrice.creator.creatorType?.tag?.endsWith(" Creator"))
        )
      : campaign.budgets;
  const grouped = Object.values(
    groupBy(budgets, (b: any) => b.creatorPrice.creatorId)
  );

  console.log("budgets", campaign);

  const bookedPostsByPlatform = (platform) => {
    return budgets
      .filter(
        (item: any) => item.creatorPrice.platform?.toLowerCase() === platform
      )
      .map((item: any) => item.budget)
      .reduce((a: number, b: number) => a + b, 0);
  };

  const totalBookedPosts = () => {
    if (!lowerCasePlatforms) {
      return budgets
        .map((item: any) => item.budget)
        .reduce((a: number, b: number) => a + b, 0);
    } else {
      return budgets
        .filter((item: any) =>
          lowerCasePlatforms?.includes(
            item.creatorPrice.platform?.toLowerCase()
          )
        )
        .map((item: any) => item.budget)
        .reduce((a: number, b: number) => a + b, 0);
    }
  };

  const bookedPriceByPlatform = (platform) => {
    return budgets
      .filter(
        (item: any) => item.creatorPrice.platform?.toLowerCase() === platform
      )
      .map(
        (item: any) =>
          item.budget * (item?.customCreatorPrice || item.creatorPrice.price)
      )
      .reduce((a: number, b: number) => a + b, 0);
  };

  const totalBookedPrice = () => {
    if (!lowerCasePlatforms) {
      return budgets
        .map(
          (item: any) =>
            item.budget * (item?.customCreatorPrice || item.creatorPrice.price)
        )
        .reduce((a: number, b: number) => a + b, 0);
    } else {
      return budgets
        .filter((item: any) =>
          lowerCasePlatforms?.includes(
            item.creatorPrice.platform?.toLowerCase()
          )
        )
        .map(
          (item: any) =>
            item.budget * (item?.customCreatorPrice || item.creatorPrice.price)
        )
        .reduce((a: number, b: number) => a + b, 0);
    }
  };

  const realizedPostsByPlatform = (platform) => {
    return campaign.post.filter(
      (item: any) =>
        item.type?.toLowerCase() === platform?.toLowerCase() &&
        budgets.some(
          (b: any) =>
            b.creatorPrice.platform?.toLowerCase() ===
              item.type?.toLowerCase() &&
            b.creatorPrice.username?.toLowerCase() ===
              item.username?.toLowerCase()
        )
    ).length;
  };

  const totalRealizedPosts = () => {
    if (!lowerCasePlatforms) {
      return campaign.post.filter((item: any) =>
        budgets.some(
          (b: any) =>
            b.creatorPrice.platform === item.type &&
            b.creatorPrice.username?.toLowerCase() ===
              item.username?.toLowerCase()
        )
      ).length;
    } else {
      return campaign.post.filter(
        (item: any) =>
          lowerCasePlatforms?.includes(item.type?.toLowerCase()) &&
          budgets.some(
            (b: any) =>
              b.creatorPrice.platform === item.type &&
              b.creatorPrice.username?.toLowerCase() ===
                item.username?.toLowerCase()
          )
      ).length;
    }
  };

  const realizedPriceByPlatform = (platform) => {
    return campaign.post
      .filter((item: any) => item.type?.toLowerCase() === platform)
      .map((item: any) => {
        const b = budgets.find(
          (b: any) =>
            b.creatorPrice.platform === item.type &&
            b.creatorPrice.username?.toLowerCase() ===
              item.username?.toLowerCase()
        );
        return b?.customCreatorPrice || b?.creatorPrice.price || 0;
      })
      .reduce((a: number, b: any) => a + b, 0);
  };

  const totalRealizedPrice = () => {
    if (!lowerCasePlatforms) {
      return campaign.post
        .map((item: any) => {
          const b = budgets.find(
            (b: any) =>
              b.creatorPrice.platform === item.type &&
              b.creatorPrice.username?.toLowerCase() ===
                item.username?.toLowerCase()
          );
          return b?.customCreatorPrice || b?.creatorPrice.price || 0;
        })
        .reduce((a: number, b: any) => a + b, 0);
    } else {
      return campaign.post
        .filter((item: any) =>
          lowerCasePlatforms?.includes(item.type?.toLowerCase())
        )
        .map((item: any) => {
          const b = budgets.find(
            (b: any) =>
              b.creatorPrice.platform === item.type &&
              b.creatorPrice.username?.toLowerCase() ===
                item.username?.toLowerCase()
          );
          return b?.customCreatorPrice || b?.creatorPrice.price || 0;
        })
        .reduce((a: number, b: any) => a + b, 0);
    }
  };

  let budget = useMemo(() => {
    let result = 0;
    campaignBudgets?.forEach((b) => {
      if (lowerCasePlatforms?.includes(b.platform)) {
        if (selectedTags?.length > 0) {
          const tagName = tags?.find((t) => t.id === b.creatorTypeId);
          if (tagName && selectedTags?.includes(tagName.tag)) {
            result += b.budget;
          }
        } else {
          result += b.budget;
        }
      }
    });
    if (campaignBudgets?.length === 0) {
      // TODO: Handles legacy campaign budget format, delete when all campaigns stop using old format
      if (lowerCasePlatforms?.includes("instagram")) {
        result += campaign.instagramBudget;
      }
      if (lowerCasePlatforms?.includes("tiktok")) {
        result += campaign.tiktokBudget;
      }
    }
    return result;
  }, [campaignBudgets, lowerCasePlatforms, selectedTags, tags]);
  console.log("selectedTags", selectedTags);

  const budgetByPlatform = (platform) => {
    let result = 0;
    campaignBudgets?.forEach((b) => {
      if (platform === b.platform) {
        if (selectedTags?.length > 0) {
          const tagName = tags?.find((t) => t.id === b.creatorTypeId);
          if (tagName && selectedTags?.includes(tagName.tag)) {
            result += b.budget;
          }
        } else {
          result += b.budget;
        }
      }
    });
    if (campaignBudgets?.length === 0) {
      // TODO: Handles legacy campaign budget format, delete when all campaigns stop using old format
      if (platform === "instagram") {
        result += campaign.instagramBudget;
      }
      if (platform === "tiktok") {
        result += campaign.tiktokBudget;
      }
    }
    return result;
  };
  const budgetHeaders = ["Platforms", "Posts", "Budget", "Booked", "Posted"];

  const headers = ["Creator", "Posting Progress"];
  const removeBudget = async (id: string) => {
    if (confirm("Are you sure you want to delete this budget?")) {
      await deleteBudget(id);
      mutate();
    }
  };

  const getBrandIcon = (platform) => {
    if (platform === "instagram") {
      return <IconBrandInstagram className="icon" />;
    } else if (platform === "tiktok") {
      return <IconBrandTiktok className="icon" />;
    } else if (platform === "twitter") {
      return <IconBrandX className="icon" />;
    } else if (platform === "youtube") {
      return <IconBrandYoutube className="icon" />;
    }
  };

  console.log({ budget });
  if (isMobile === null) {
    return <Loader full={true} />;
  }

  return (
    <Flex direction="column" gap="5">
      <Flex className="box" direction="column" p="5" gap="5" justify="between">
        <Flex direction="row" gap="2" justify="between">
          <Heading size="4">Budget Analytics</Heading>
        </Flex>
        {isMobile ? (
          <Flex direction="column" gap="2">
            <Flex direction="row" justify="start">
              <Flex width="10%"></Flex>
              <Flex width="25%">
                <Text size="2">Posts</Text>
              </Flex>
              <Flex width="21%">
                <Text size="2">Budget</Text>
              </Flex>
              <Flex width="21%">
                <Text size="2">Booked</Text>
              </Flex>
              <Flex width="21%">
                <Text size="2">Posted</Text>
              </Flex>
            </Flex>
            {lowerCasePlatforms &&
              lowerCasePlatforms.map((platform) => (
                <>
                  {parseInt(budgetByPlatform(platform) as any) ? (
                    <Flex direction="row" justify="start" align="center">
                      <Flex width="10%">
                        <Heading size="3">{getBrandIcon(platform)}</Heading>
                      </Flex>
                      <Flex width="25%">
                        <Heading size="3">
                          {realizedPostsByPlatform(platform)}/
                          {bookedPostsByPlatform(platform)}
                        </Heading>
                      </Flex>
                      <Flex width="21%">
                        <Heading size="3">
                          ${budgetByPlatform(platform).toFixed(2)}
                        </Heading>
                      </Flex>
                      <Flex width="21%">
                        <Heading size="3">
                          ${bookedPriceByPlatform(platform).toFixed(2)}
                        </Heading>
                      </Flex>
                      <Flex width="21%">
                        <Heading size="3">
                          ${realizedPriceByPlatform(platform).toFixed(2)}
                        </Heading>
                      </Flex>
                    </Flex>
                  ) : null}
                </>
              ))}
            <Separator my="2" size="4" />
            <Flex direction="row" justify="start" align="center">
              <Flex width="10%">
                <IconPlus className="icon" />
              </Flex>
              <Flex width="25%">
                <Heading size="3">
                  {totalRealizedPosts()}/{totalBookedPosts()}
                </Heading>
              </Flex>
              <Flex width="21%">
                <Heading size="3">${budget}</Heading>
              </Flex>
              <Flex width="21%">
                <Heading size="3">${totalBookedPrice().toFixed(2)}</Heading>
              </Flex>
              <Flex width="21%">
                <Heading size="3">${totalRealizedPrice().toFixed(2)}</Heading>
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <Flex direction="column" gap="2">
            <Flex direction="row" justify="start">
              {budgetHeaders.map((item: any) => (
                <Flex width="15%" key={item}>
                  <Text size="2">{item}</Text>
                </Flex>
              ))}
            </Flex>
            {lowerCasePlatforms &&
              lowerCasePlatforms.map((platform) => (
                <>
                  {parseInt(budgetByPlatform(platform) as any) ? (
                    <Flex direction="row" justify="start">
                      <Flex width="15%">
                        <Heading size="5">{capitalize(platform)}</Heading>
                      </Flex>
                      <Flex width="15%">
                        <Heading size="5">
                          {realizedPostsByPlatform(platform)} /{" "}
                          {bookedPostsByPlatform(platform)}
                        </Heading>
                      </Flex>
                      <Flex width="15%">
                        <Heading size="5">
                          ${budgetByPlatform(platform).toFixed(2)}
                        </Heading>
                      </Flex>
                      <Flex width="15%">
                        <Heading size="5">
                          ${bookedPriceByPlatform(platform).toFixed(2)}
                        </Heading>
                      </Flex>
                      <Flex width="15%">
                        <Heading size="5">
                          ${realizedPriceByPlatform(platform).toFixed(2)}
                        </Heading>
                      </Flex>
                    </Flex>
                  ) : null}
                </>
              ))}
            <Flex width="75%">
              <Separator my="3" size="4" />
            </Flex>
            <Flex direction="row" justify="start">
              <Flex width="15%">
                <Heading size="5">Total</Heading>
              </Flex>
              <Flex width="15%">
                <Heading size="5">
                  {totalRealizedPosts()} / {totalBookedPosts()}
                </Heading>
              </Flex>
              <Flex width="15%">
                <Heading size="5">${budget}</Heading>
              </Flex>
              <Flex width="15%">
                <Heading size="5">${totalBookedPrice().toFixed(2)}</Heading>
              </Flex>
              <Flex width="15%">
                <Heading size="5">${totalRealizedPrice().toFixed(2)}</Heading>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex className="box" direction="column" gap="5" p="5">
        <Heading size="4">Creators Posting Progress</Heading>
        {grouped.length === 0 ? (
          <Text>No budgets yet.</Text>
        ) : (
          <Table.Root>
            <Table.Header>
              <Table.Row>
                {headers.map((item: any) => (
                  <Table.ColumnHeaderCell key={item}>
                    {item}
                  </Table.ColumnHeaderCell>
                ))}
                <Table.ColumnHeaderCell justify="end" key="Actions">
                  Actions
                </Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {grouped.map((b: any) => {
                const filteredBudgets = b.filter((budget) =>
                  lowerCasePlatforms?.includes(
                    budget.creatorPrice.platform.toLowerCase()
                  )
                );
                if (filteredBudgets.length === 0) {
                  return null;
                }
                return (
                  <Table.Row key={b[0].id}>
                    <Table.Cell width="20%">
                      <Flex
                        direction="row"
                        align="center"
                        gap="2"
                        overflowX="scroll"
                      >
                        <Avatar
                          src={`/image-upload/${b[0].creatorPrice.avatar}`}
                          fallback={
                            Array.from(
                              b[0].creatorPrice.creator.name
                            )[0] as string
                          }
                          radius="full"
                        />
                        <Link href={`/creators/${b[0].creatorPrice.creatorId}`}>
                          {b[0].creatorPrice.creator.name}
                        </Link>
                      </Flex>
                    </Table.Cell>
                    <Table.Cell>
                      <Flex
                        direction="column"
                        gap="5"
                        justify="center"
                        height="100%"
                      >
                        {filteredBudgets.map((budget: any) => (
                          <Budget
                            key={budget.id}
                            budget={budget}
                            posts={campaign.post}
                            campaignId={campaign.id}
                            mutate={mutate}
                            multiple={b.length > 1}
                          />
                        ))}
                      </Flex>
                    </Table.Cell>
                    <Table.Cell width="10%">
                      <Flex direction="column" gap="2">
                        {filteredBudgets.map((budget: any) => (
                          <Flex
                            direction="row"
                            gap="2"
                            align="center"
                            justify="end"
                            key={budget}
                          >
                            <NewEditBudget
                              mutate={mutate}
                              budget={budget}
                              creator={budget.creatorPrice.creator}
                              price={budget.creatorPrice}
                            />
                            <IconButton
                              variant="outline"
                              onClick={() => removeBudget(budget.id)}
                            >
                              <IconTrash className="icon-accent" />
                            </IconButton>
                          </Flex>
                        ))}
                      </Flex>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table.Root>
        )}
      </Flex>
    </Flex>
  );
};

export default CreatorsTab;
